<script lang="ts" setup>
import type { QuestionItem } from '@/types/layout';

interface Props {
  className?: string;

  [x: string]: any;
}

defineProps<Props>();

const question = ref<QuestionItem[]>([
  {
    title: 'Can I Play HANDLE if I don’t know how to play bridge?',
    description:
      'Yes! Though bridge takes time to learn - especially the bidding, Handle tests you on card-play only. There’s a walk-thru of the play rules and you’ll be off and running in seconds, with guidance and hints along the way. Then if you want to improve your Handle play - or get stuck in to bridge itself (BTW, fabulous idea!) - take free lessons on Bridge128 and watch your rating soar.'
  },
  {
    title: 'How do the HANDLE levels work? What level am I?',
    description: `The ‘1 to 8’ levels of Handle correspond to the learning levels of Bridge128. Level 1 is for complete beginners with no knowledge. Once you know the basic rules of bidding and play you are a Level 2 beginner. Levels 3 & 4 are improver, levels 5 & 6 intermediate and levels 7 & 8 advanced. Handle is aimed at ALL players <em>below</em> tournament standard (Level 9), testing technique and knowledge of bridge odds. Find out more about levels under the About tab of Bridge128.`
  },
  {
    title:
      'How can I play HANDLE on my own when bridge is a game for 4 players?',
    description: `Bridge is always played by two players against two but during the play phase in bridge, i.e. as in Handle, one player i.e. you – known as ‘declarer’, plays both your own cards and your partner’s cards. Your two opponents – known as ‘defenders’ - are robot players, Lefty & Righty, whose job it is to defeat you. They have a knack of playing the right card at the right time, but best technique will always get the better of them.`
  },
  {
    title: 'Are HANDLE deals random or fixed?',
    description: `Fixed. Handle deals are carefully curated, with each deal of the week set to a difficulty rating. Sunday is beginner day, through to Saturday, getting trickier as the week goes on. But importantly, the daily rating is set to the <em>minimum</em> required tricks, so more experienced players can still test their skills - and can boost their ranking - by stretching for overtricks on easier deals, making every day of the week a challenge.`
  }
]);
</script>

<template>
  <section :class="['grid gap-8', className]">
    <h2 class="text-[28px] leading-9 font-medium text-brand-navy-700">
      Frequently Asked Questions
    </h2>
    <div class="grid gap-6 pb-6">
      <QuestionItem
        v-for="(item, index) in question"
        :key="index"
        :question="item"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped></style>
