<script lang="ts" setup>
type ListItem = {
  title: string;
  description: string;
  icon: string;
};
interface Props {
  className?: string;

  [x: string]: any;
}

defineProps<Props>();

const listItems = ref<ListItem[]>([
  {
    title: 'Your Handle Play Rating',
    description:
      'Play for a weekly rating then an overall Handle Rating, averaged over the past four weeks. See your level progress over weeks, months and years.',
    icon: 'landing/shield'
  },
  {
    title: 'Track your wins',
    description:
      'Get a detailed breakdown of your winning rate across every level, and between trumps and no trumps. Which areas do you need to focus on?',
    icon: 'landing/cup'
  },
  {
    title: 'Make overtricks for bonus points',
    description:
      'With some Handles you can make overtricks, more tricks that you need. Aim for the stars to win bonus points and up your rating.',
    icon: 'landing/bonus'
  },
  {
    title: 'Up your game with Bridge128',
    description:
      'Access free and paid tutorials on Bridge128 with video/quiz lessons including study deals for practice.',
    icon: 'landing/rate'
  }
]);
</script>

<template>
  <section
    :class="[
      'grid md:grid-cols-2 gap-y-6 md:gap-y-[30px] gap-x-6 md:gap-x-14',
      className
    ]"
  >
    <h2
      class="col-span-2 text-[28px] leading-9 font-medium text-brand-navy-700"
    >
      Track your progress, analyse your stats
    </h2>
    <div
      class="order-3 md:order-2 col-span-2 md:col-span-1 flex justify-center items-center px-6 py-12 bg-brand-lightest-blue rounded-[10px] overflow-hidden"
    >
      <NuxtImg
        src="/images/statistic-example.png"
        alt="Statistic"
        class="max-w-[500px] w-full"
      />
    </div>
    <div class="order-2 md:order-3 col-span-2 md:col-span-1 grid gap-7">
      <div class="grid gap-4">
        <div
          v-for="(item, index) in listItems"
          :key="index"
          class="grid gap-3 pb-4 last:pb-0 text-brand-navy-600 border-b border-brand-light-gray last:border-none"
        >
          <div class="flex items-center">
            <NuxtIcon :name="item.icon" filled class="item-icon mr-2" />
            <h3 class="text-xl leading-[26px] font-medium">
              {{ item.title }}
            </h3>
          </div>
          <p class="text-base leading-5">{{ item.description }}</p>
        </div>
      </div>
      <div class="hidden md:block">
        <Button color="secondary" to="/sign-up"> Start for free </Button>
      </div>
    </div>
    <Button color="secondary" class-name="order-4 md:hidden" to="/sign-up">
      Start for free
    </Button>
  </section>
</template>

<style lang="scss" scoped>
:deep(.item-icon svg) {
  @apply w-[26px] h-[26px] mb-0;
}
</style>
