<script lang="ts" setup>
import type { QuestionItem } from '@/types/layout';

interface Props {
  question: QuestionItem;
  className?: string;

  [x: string]: any;
}

defineProps<Props>();

const isOpened = ref(false);

const iconName = computed(() => {
  return isOpened.value ? 'mdi-light:minus' : 'mdi-light:plus';
});

function toggle() {
  isOpened.value = !isOpened.value;
}
</script>

<template>
  <div
    class="grid gap-3 pb-6 last:pb-0 text-brand-navy-600 border-b border-brand-navy-100 last:border-none"
  >
    <div class="flex justify-between items-center" @click="toggle">
      <h3 class="text-lg leading-6 cursor-pointer">
        {{ question.title }}
      </h3>
      <Icon
        :name="iconName"
        size="16"
        class="text-brand-navy-600 ml-2 cursor-pointer"
      />
    </div>
    <div v-if="isOpened">
      <p class="text-base leading-5" v-html="question.description" />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
