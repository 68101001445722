<script lang="ts" setup>
interface Props {
  className?: string;

  [x: string]: any;
}

defineProps<Props>();

const benefits = ref<{ text: string; icon: string }[]>([
  {
    text: 'Track your rating progress',
    icon: 'landing/star'
  },
  {
    text: 'Get a perfect streak',
    icon: 'landing/fire'
  },
  {
    text: 'Catch up on missed games',
    icon: 'landing/cards'
  },
  {
    text: 'Access to free tutorials',
    icon: 'landing/play'
  }
]);
</script>

<template>
  <section
    :class="[
      'relative flex flex-col md:flex-row bg-white rounded-[10px] overflow-hidden',
      className
    ]"
  >
    <div
      class="relative w-full lg:max-w-[480px] flex items-center justify-center p-6 lg:py-11 lg:px-14 z-20 bg-white"
    >
      <div class="grid gap-6 text-brand-navy-600">
        <div class="grid gap-6">
          <h2
            class="text-[28px] leading-9 lg:text-[32px] lg:leading-[42px] font-medium"
          >
            Go premium and enjoy all the benefits
          </h2>
          <ul class="grid gap-3">
            <li
              v-for="(benefit, index) in benefits"
              :key="index"
              class="benefit-item"
            >
              <NuxtIcon :name="benefit.icon" filled class="mr-2" />
              <span class="text-base leading-5">{{ benefit.text }}</span>
            </li>
          </ul>
        </div>
        <Button class-name="w-full lg:w-fit" to="/sign-up">
          Go Premium now
        </Button>
      </div>
    </div>
    <div
      class="flex justify-end mt-4 md:mt-0 md:absolute md:top-0 md:right-0 h-full z-10"
    >
      <NuxtImg
        src="/images/handles-comparison-with-background.png"
        alt="Handles comparison"
        class="hidden lg:block h-full w-auto"
      />
      <NuxtImg
        src="/images/handles-comparison-with-background-mobile.png"
        alt="Handles comparison"
        class="md:hidden w-full h-auto"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.benefit-item {
  @apply inline-flex items-center;

  :deep(.nuxt-icon svg) {
    @apply h-5 w-5 mb-0;
  }
}
</style>
